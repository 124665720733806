import React from 'react';
import styled, { css } from 'styled-components';
import Styles from '../../../../helpers/styles';
import Icon, { ICON_TYPES } from '../../../../components/Icon';

const TableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.3),
    0 2px 6px 2px rgba(0, 0, 0, 0.15);
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0px;
  width: 100%;
  table-layout: fixed;
`;

interface MobileHiddenProps {
  mobileHidden?: boolean;
}

const SearchSection = styled.div`
  background-color: var(--color-surface-two);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 5px;
`;

const FootSection = styled.div`
  background-color: var(--color-surface-two);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 0px;
`;

const TableHeader = styled.th<MobileHiddenProps>`
  color: var(--color-on-surface);
  text-align: left;
  padding: 15px;
  background-color: var(--color-surface-two);
  border-bottom: 1px solid rgba(211, 227, 253, 0.08);

  @media (max-width: ${Styles.mobileBreakpoint}) {
    ${({ mobileHidden }) => (mobileHidden ? 'display: none;' : '')}
  }
`;

interface ClickableProps {
  clickable: boolean;
  sorted: boolean;
}

const SortedCSS = css`
  > .icon {
    transform: translateY(0px);
    opacity: 1;
  }
  color: #d3e3fd;
`;

const UnsortedCSS = css`
  &:hover {
    > .icon {
      transform: translateY(0px);
      opacity: 0.7;
    }
  }
`;

const HeaderContent = styled.div<ClickableProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;

  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}

  > .icon {
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s ease;
  }

  ${({ sorted }) => (sorted ? SortedCSS : UnsortedCSS)}
`;

const TableCell = styled.td<MobileHiddenProps>`
  color: var(--color-on-surface);
  text-align: left;
  padding: 15px;
  height: 40px;
  @media (max-width: ${Styles.mobileBreakpoint}) {
    ${({ mobileHidden }) => (mobileHidden ? 'display: none;' : '')}
  }
  border-bottom: 1px solid rgba(211, 227, 253, 0.08);
`;

const ClickableStyles = css`
  cursor: pointer;
  &:hover > td {
    background-color: var(--color-surface-two);
  }
`;

interface RowProps {
  hasOnClick: boolean;
}

const Row = styled.tr<RowProps>`
  background-color: var(--color-surface);

  ${(props) => (props.hasOnClick ? ClickableStyles : '')}
`;

interface InvertableProps {
  inverted: boolean;
}

export const SortIcon = styled(Icon)<InvertableProps>`
  ${({ inverted }) => (inverted ? 'rotate: 180deg;' : '')}
  transition: rotate 0.3s ease;
`;

interface TableRow {
  key: string;
  onClick?: () => void;
  cells: React.ReactNode[];
  expanded?: boolean;
  expandedSection?: React.ReactNode;
}

interface TableProps {
  rows: TableRow[];
  headers: React.ReactNode[];
  mobileHiddenIndices?: number[];
  sortableIndices?: number[];
  sortedIndex?: number;
  sortDirection?: 'asc' | 'desc';
  sort?: (number: number) => void;
}

const GenericTable: React.FC<TableProps> = ({
  rows,
  headers,
  mobileHiddenIndices: mobileHiddenIndicesProp,
  sortableIndices: sortableIndicesProp,
  sortedIndex,
  sortDirection,
  sort,
}) => {
  const sortableIndices = sortableIndicesProp ?? [];
  const mobileHiddenIndices = mobileHiddenIndicesProp ?? [];

  const mappedRows = rows.flatMap((row) => {
    const mappedCells = row.cells.map((cell, i) => {
      const mobileHidden = mobileHiddenIndices.includes(i);
      return (
        <TableCell mobileHidden={mobileHidden} key={i}>
          {cell}
        </TableCell>
      );
    });

    const singleRow = (
      <Row
        hasOnClick={Boolean(row.onClick)}
        key={row.key}
        onClick={row.onClick}
      >
        {mappedCells}
      </Row>
    );

    if (!row.expanded || !row.expandedSection) {
      return singleRow;
    }

    const expandedRow = (
      <Row hasOnClick={false} key={`expanded-${row.key}`}>
        <td colSpan={mappedCells.length}>{row.expandedSection}</td>
      </Row>
    );

    return [singleRow, expandedRow];
  });

  const mappedHeaderCells = headers.map((header, i) => {
    const mobileHidden = mobileHiddenIndices.includes(i);
    const sortable = sortableIndices.includes(i);

    const sorted = sortedIndex === i;
    const icon = sortable ? (
      <SortIcon
        size={16}
        inverted={sortDirection === 'asc' && sorted}
        type={ICON_TYPES.CIRCLE_DOWN}
      />
    ) : null;

    return (
      <TableHeader mobileHidden={mobileHidden} key={i}>
        <HeaderContent
          sorted={sorted}
          onClick={sort ? () => sort(i) : undefined}
          clickable={sortable}
        >
          {header}
          {icon}
        </HeaderContent>
      </TableHeader>
    );
  });

  return (
    <TableContainer>
      <SearchSection />
      <Table>
        <thead>
          <tr>{mappedHeaderCells}</tr>
        </thead>
        <tbody>{mappedRows}</tbody>
      </Table>
      <FootSection />
    </TableContainer>
  );
};

export default GenericTable;
