import type EventModel from './EventModel';
import Model from './Model';
import RequestAndParse from './RequestAndParse';
import type RosterCompetitorModel from './RosterCompetitorModel';
import type EventPickModel from './EventPickModel';
import singletons from './singletons';
import Request from './Request';
import type CompetitorModel from './CompetitorModel';
import type LeagueMemberModel from './LeagueMemberModel';
import type DarkHorsePickModel from './DarkHorsePickModel';
import LeagueEventModel from './LeagueEventModel';
import type RosterLogModel from './RosterLogModel';

interface ResultCompetitor {
  points: number;
  place: number;
  name: string;
  darkHorse: boolean;
}

export interface EventCompetitorResult {
  teamName: string;
  points: number;
  competitors: ResultCompetitor[];
}

export interface EventResult {
  updatedAt: string;
  results: EventCompetitorResult[];
}

interface TeamCompetitor {
  name: string;
  id: string;
}

export interface TeamModel {
  name: string;
  competitors: Record<string, TeamCompetitor>;
}

interface Standing {
  leagueMemberId: string;
  teamName: string;
  totalPoints: number;
  leaguePoints: number;
  place: number;
}

export type LeagueStandings = Standing[];

class LeagueModel extends Model {
  static apiPath = '/leagues';

  public name: string;
  public isPrivate: boolean;
  public scoringType: string;
  public leagueType: string;

  public events?: EventModel[];
  public rosterCompetitors?: RosterCompetitorModel[];

  public rosterSize: number;
  public pickCount: number;
  public allowDarkHorse: boolean;
  public ownerId: string;
  public password: string;

  public results: Record<string, EventResult> = {};

  // These properties are not always available
  public playerCount?: number;

  get isOwner(): boolean {
    return (
      this.ownerId ===
      (singletons.cloakedUserId ?? singletons.auth?.currentUser?.uid)
    );
  }

  async getFreeAgents({ name }): Promise<Record<string, CompetitorModel>> {
    const results = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/free-agent-search?name=${name}`,
    );

    return Model.parseFetchData(results);
  }

  async addToRoster({
    competitorId,
    leagueMemberId,
  }): Promise<RosterCompetitorModel | null> {
    const results = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/rosters`,
      {
        method: 'POST',
        body: {
          competitorId,
          leagueMemberId,
        },
      },
    );

    return Model.parseFetchData(results);
  }

  async removeFromRoster(rosterCompetitorId: string): Promise<any> {
    return Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/rosters/${rosterCompetitorId}`,
      {
        method: 'DELETE',
      },
    );
  }

  async makePick(
    eventId: string,
    rosterCompetitorId: string,
  ): Promise<EventPickModel> {
    const pick = await RequestAndParse(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/picks`,
      {
        method: 'POST',
        body: {
          eventId,
          rosterCompetitorId,
        },
      },
    );

    return pick.eventpicks;
  }

  async deletePick(
    eventId: string,
    rosterCompetitorId: string,
  ): Promise<EventPickModel> {
    const pick = await RequestAndParse(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/picks`,
      {
        method: 'DELETE',
        body: {
          eventId,
          rosterCompetitorId,
        },
      },
    );

    return pick as EventPickModel;
  }

  async getPicks(eventId: string): Promise<Record<string, EventPickModel>> {
    const picks = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/picks/${eventId}`,
    );

    return Model.parseFetchData(picks);
  }

  async getEvents(): Promise<Record<string, EventModel>> {
    // TODO: Get the events for this league in particular
    const results = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/events`,
    );

    return Model.parseFetchData(results);
  }

  async getResults(eventId: string): Promise<EventResult | null> {
    if (!eventId) {
      return null;
    }

    if (this.results[eventId]) {
      return this.results[eventId];
    }

    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/events/${eventId}/results`,
    );

    this.results[eventId] = data;
    return data;
  }

  async getStandings(): Promise<LeagueStandings> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/standings`,
    );

    return data;
  }

  async getLeagueMembers(): Promise<Record<string, LeagueMemberModel>> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/members`,
    );
    return Model.parseFetchData(data);
  }

  async getRosterCompetitors(): Promise<Record<string, RosterCompetitorModel>> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/roster-competitors`,
    );
    return Model.parseFetchData(data);
  }

  async getActiveCompetitors(): Promise<Record<string, CompetitorModel>> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/active-competitors`,
    );
    return Model.parseFetchData(data);
  }

  static async create({ name, password }): Promise<LeagueModel | null> {
    const request = await Request(this.apiPath, {
      method: 'POST',
      body: {
        name,
        password,
      },
    });

    return Model.parseFetchData(request);
  }

  static async join({ password, leagueId, teamName }): Promise<LeagueModel> {
    const request = await Request(`${this.apiPath}/${leagueId}/join`, {
      method: 'POST',
      body: {
        password,
        teamName,
      },
    });

    return Model.parseFetchData(request);
  }

  static async search(name: string): Promise<LeagueModel[]> {
    if (name.length < 3) {
      return [];
    }

    const request = await RequestAndParse(
      `${this.apiPath}/search?name=${name}`,
      {},
      false,
    );

    return request?.leagues ?? [];
  }

  async getDarkHorses(
    eventId: string,
  ): Promise<Record<string, CompetitorModel>> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/${eventId}/dark-horses`,
    );
    return Model.parseFetchData(data);
  }

  async getDarkHorseRequiredPicks(eventId: string): Promise<number> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/${eventId}/dark-horse-meta`,
    );

    return data.requiredPicks;
  }

  async createDarkHorse({
    eventId,
    competitorId,
    priority,
  }): Promise<DarkHorsePickModel> {
    const results = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/${eventId}/dark-horse-picks`,
      {
        method: 'POST',
        body: {
          competitorId,
          priority,
        },
      },
    );

    return Model.parseFetchData(results);
  }

  async deleteDarkHorsePick({
    eventId,
    darkHorsePickId,
  }): Promise<Record<string, DarkHorsePickModel>> {
    const results = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/${eventId}/dark-horse-picks/${darkHorsePickId}`,
      { method: 'DELETE' },
    );

    return Model.parseFetchData(results);
  }

  async moveDarkHorsePickUp({
    eventId,
    darkHorsePickId,
  }): Promise<Record<string, DarkHorsePickModel>> {
    const results = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/${eventId}/dark-horse-picks/${darkHorsePickId}`,
      { method: 'PUT', body: { direction: 'up' } },
    );

    return Model.parseFetchData(results);
  }

  async moveDarkHorsePickDown({
    eventId,
    darkHorsePickId,
  }): Promise<Record<string, DarkHorsePickModel>> {
    const results = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/${eventId}/dark-horse-picks/${darkHorsePickId}`,
      { method: 'PUT', body: { direction: 'down' } },
    );

    return Model.parseFetchData(results);
  }

  async getDarkHorsePicks(
    eventId: string,
  ): Promise<Record<string, DarkHorsePickModel>> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/${eventId}/dark-horse-picks`,
    );
    return Model.parseFetchData(data);
  }

  async updateTeamName(teamName: string): Promise<LeagueMemberModel> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/team-name`,
      {
        method: 'PUT',
        body: { teamName },
      },
    );

    return Model.parseFetchData(data);
  }

  async update({ leagueName, rosterSize, pickCount }): Promise<LeagueModel> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}`,
      {
        method: 'PUT',
        body: { leagueName, rosterSize, pickCount },
      },
    );

    const parsedData = Model.parseFetchData(data);

    this.name = parsedData.name;
    this.rosterSize = parsedData.rosterSize;
    this.pickCount = parsedData.pickCount;
    return this;
  }

  async getLeagueEvents(): Promise<LeagueEventModel[]> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/league-events`,
    );

    return data.map((row) => new LeagueEventModel(row));
  }

  async getRosterLogs(): Promise<RosterLogModel[]> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/roster-logs`,
    );

    return Model.parseSortedFetchData(data);
  }

  async fetchFreeAgents({
    pageNumber,
    sortBy,
    sortDir,
  }: {
    pageNumber: number;
    sortBy: string;
    sortDir: string;
  }): Promise<[number, CompetitorModel[]]> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/free-agents?page=${pageNumber}&sortBy=${sortBy}&sortDir=${sortDir}`,
    );

    return [data.totalFreeAgents, Model.parseSortedFetchData(data.freeAgents)];
  }

  async addFreeAgent({ competitorId }): Promise<any> {
    const data = await Request(
      `${(this.constructor as typeof LeagueModel).apiPath}/${this.id}/free-agents`,
      {
        method: 'POST',
        body: { competitorId },
      },
    );

    console.log(data);
  }
}

Model.registerModel('Leagues', LeagueModel);

export default LeagueModel;
