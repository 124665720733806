import React from 'react';
import Icon, { ICON_TYPES } from './Icon';
import classNames from '../helpers/classNames';

interface PaginatedTableProps {
  headers: React.ReactNode[];
  rows: React.ReactNode[][];
  pageNumber: number;
  pageSize: number;
  totalRows: number;
  changePage: (pageNumber: number) => void;
  sortableIndices?: number[];
  ascDefaultIndices?: number[];
  sortedIndex?: number;
  sortDirection?: 'ASC' | 'DESC';
  sort?: (number: number) => void;
}

const PaginatedTable: React.FC<PaginatedTableProps> = ({
  headers,
  rows,
  pageNumber,
  pageSize,
  totalRows,
  changePage,
  ascDefaultIndices,
  sortableIndices,
  sortedIndex,
  sortDirection,
  sort,
}) => {
  return (
    <div className="w-full">
      <div className="bg-sky-900 rounded-t-md h-[5px]"></div>
      <table className="table-auto w-full">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                className="bg-sky-900 text-gray-100 text-left p-3 border-b border-sky-700"
                key={index}
              >
                <div
                  onClick={
                    sort && sortableIndices?.includes(index)
                      ? () => sort(index)
                      : undefined
                  }
                  className={classNames('group flex flex-row items-center', {
                    'cursor-pointer gap-x-1':
                      sort && sortableIndices?.includes(index),
                  })}
                >
                  <span>{header}</span>
                  {sortableIndices?.includes(index) && sort ? (
                    <Icon
                      className={classNames(
                        'transition-all opacity-0 translate-y-[-10px]',
                        {
                          'rotate-180':
                            (sortDirection === 'ASC' &&
                              sortedIndex === index) ||
                            (sortedIndex !== index &&
                              ascDefaultIndices?.includes(index)),
                          'opacity-100 translate-y-0': sortedIndex === index,
                          'group-hover:opacity-70 group-hover:translate-y-0':
                            sortedIndex !== index,
                        },
                      )}
                      size={16}
                      type={ICON_TYPES.CIRCLE_DOWN}
                    />
                  ) : null}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, idx) => (
            <tr key={idx}>
              {row.map((cell, idx) => (
                <td
                  className="bg-sky-950 text-gray-100 p-3 border-b border-sky-600"
                  key={idx}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="bg-sky-900 p-3 rounded-b-md flex flex-row justify-between w-full">
        <p className="text-gray-100">
          <span className="hidden md:inline">{'Showing '}</span>
          <span className="font-semibold">
            {(pageNumber - 1) * pageSize + 1}
          </span>
          <span>{' - '}</span>
          <span className="font-semibold">
            {Math.min(pageNumber * pageSize, totalRows)}
          </span>
          <span>{' of '}</span>
          <span className="font-semibold">{totalRows}</span>
          <span className="hidden md:inline">{' results'}</span>
        </p>
        <div className="grid grid-cols-2 gap-x-2">
          <button
            className="border border-sky-600 py-1 px-2 rounded-md bg-sky-700 hover:bg-sky-600 text-gray-100 disabled:text-gray-300 disabled:bg-sky-900 disabled:border-sky-800"
            disabled={pageNumber <= 1}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              changePage(pageNumber - 1);
            }}
          >
            {'Previous'}
          </button>
          <button
            className="border border-sky-600 py-1 px-2 rounded-md bg-sky-700 hover:bg-sky-600 text-gray-100 disabled:text-gray-300 disabled:bg-sky-800"
            disabled={pageNumber * pageSize >= totalRows}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              changePage(pageNumber + 1);
            }}
          >
            {'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginatedTable;
