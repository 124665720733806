import React from 'react';

interface ModalProps {
  children: React.ReactNode;
  footer: React.ReactNode;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, footer, onClose }) => {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500/75 transition-opacity"
        aria-hidden="true"
      ></div>

      <div
        className="fixed inset-0 z-10 w-screen overflow-y-auto cursor-pointer"
        onClick={onClose}
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            onClick={(event) => event.stopPropagation()}
            className="cursor-auto relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              {children}
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6">{footer}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
