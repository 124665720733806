import React from 'react';
import type LeagueModel from '../../../api/LeagueModel';
import PaginatedTable from '../../../components/PaginatedTable';
import type CompetitorModel from '../../../api/CompetitorModel';
import Icon, { ICON_TYPES } from '../../../components/Icon';
import Modal from '../../../components/Modal';

interface FreeAgentProps {
  league: LeagueModel;
}

const SORT_KEYS = ['name', 'rating', 'pdga_number'];

const FreeAgents: React.FC<FreeAgentProps> = ({ league }) => {
  const [freeAgents, setFreeAgents] = React.useState<CompetitorModel[]>([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [totalFreeAgents, setTotalFreeAgents] = React.useState(0);
  const [sortedIndex, setSortedIndex] = React.useState<number>(1);
  const [sortDir, setSortDir] = React.useState<'ASC' | 'DESC'>('DESC');
  const [selectedCompetitor, setSelectedCompetitor] =
    React.useState<CompetitorModel | null>(null);
  const [isAdding, setIsAdding] = React.useState(false);
  const [forceRefresh, setForceRefresh] = React.useState(false);

  const fetchLogs = React.useCallback(
    async ({ pageNumber, sortedIndex, sortDir }) => {
      const [totalRows, freeAgents] = await league.fetchFreeAgents({
        pageNumber,
        sortBy: SORT_KEYS[sortedIndex] ?? 'rating',
        sortDir,
      });
      setFreeAgents(freeAgents);
      setTotalFreeAgents(totalRows);
      setForceRefresh(false);
    },
    [setFreeAgents, setTotalFreeAgents, setForceRefresh],
  );

  React.useEffect(() => {
    fetchLogs({ pageNumber, sortedIndex, sortDir });
  }, [pageNumber, sortedIndex, sortDir]);

  React.useEffect(() => {
    if (forceRefresh) {
      fetchLogs({ pageNumber, sortedIndex, sortDir });
    }
  }, [forceRefresh]);

  return (
    <div className="w-full">
      {selectedCompetitor && (
        <Modal
          onClose={() => setSelectedCompetitor(null)}
          footer={
            <div className="flex flex-row justify-between">
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => setSelectedCompetitor(null)}
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={isAdding}
                className="mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300  bg-blue-500 hover:bg-blue-600 sm:mt-0 sm:w-auto"
                onClick={async () => {
                  try {
                    setIsAdding(true);
                    await league.addFreeAgent({
                      competitorId: selectedCompetitor.id,
                    });
                    setForceRefresh(true);
                    setSelectedCompetitor(null);
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setIsAdding(false);
                  }
                }}
              >
                Pick Up
              </button>
            </div>
          }
        >
          <h1 className="text-lg font-semibold text-gray-800 mb-3">
            {selectedCompetitor.name}
          </h1>
          <p className="text-sm">{`Would you like to pick up ${selectedCompetitor.name} as a free agent and add them to your team?`}</p>
        </Modal>
      )}
      <h1 className="text-xl font-bold text-gray-50 mb-4">Free Agents</h1>
      <PaginatedTable
        headers={['Name', 'Rating', 'PDGA Number', '']}
        sortableIndices={[0, 1, 2]}
        rows={freeAgents.map((freeAgent) => [
          freeAgent.name,
          freeAgent.rating,
          freeAgent.pdgaNumber,
          <Icon
            key="icon"
            size={18}
            type={ICON_TYPES.CIRCLE_PLUS}
            onClick={() => setSelectedCompetitor(freeAgent)}
          />,
        ])}
        pageNumber={pageNumber}
        pageSize={25}
        totalRows={totalFreeAgents}
        changePage={setPageNumber}
        sortDirection={sortDir}
        sortedIndex={sortedIndex}
        ascDefaultIndices={[0, 2]}
        sort={(index) => {
          if (index === sortedIndex) {
            setSortDir((prevSortDir) =>
              prevSortDir === 'ASC' ? 'DESC' : 'ASC',
            );
          } else {
            setSortDir(index === 1 ? 'DESC' : 'ASC');
            setSortedIndex(index);
          }
        }}
      />
    </div>
  );
};

export default FreeAgents;
