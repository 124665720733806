import camelize from '../helpers/camelize';
import Request from './Request';

class Model {
  static apiPath = '';
  static _models: Record<string, Model> = {};
  static _defaultParams = {};
  static _hasReadAll = false;
  static _readAllPromise: null | Promise<Model[]> = null;
  static _fetchAllPromise: null | Promise<Record<string, Model>> = null;
  static _requireId = true;

  static _registeredModels = {};
  public id: string;

  constructor(params) {
    if (!params.id && (this.constructor as typeof Model)._requireId) {
      throw new Error('missing id');
    }

    for (const key in (this.constructor as typeof Model)._defaultParams) {
      this[key] = (this.constructor as typeof Model)._defaultParams[key];
    }

    for (const key in params) {
      this[camelize(key)] = params[key];
    }

    (this.constructor as typeof Model)._models[params.id] = this;
  }

  static find(id?: string | null): Model | null {
    if (!id) {
      return null;
    }

    return this._models[id];
  }

  static findOrCreate(data: Record<string, any>): Model {
    if (!data.id) {
      throw new Error('missing id');
    }

    if (this._models[data.id]) {
      // TODO: Update with new data?
      return this._models[data.id];
    }

    return new this(data);
  }

  static createUncached(data: Record<string, any>): Model {
    return new this(data);
  }

  static async read(id: string, { force = false } = {}): Promise<Model> {
    if (!id) {
      throw new Error('missing id');
    }

    if (this._models[id] && !force) {
      return this._models[id];
    }

    const modelData = await Request(`${this.apiPath}/${id}`);

    this.parseData(modelData);

    return this._models[id];
  }

  static async readAll({ force = false } = {}): Promise<Model[]> {
    if (this._hasReadAll && !force) {
      return Object.values(this._models);
    }

    if (!this._readAllPromise) {
      this._readAllPromise = this._performReadAll();
    }

    return this._readAllPromise;
  }

  static async _performReadAll(): Promise<Model[]> {
    const modelData = await Request(`${this.apiPath}`);
    this.parseData(modelData);
    return Object.values(this._models);
  }

  destroy(): this {
    delete (this.constructor as typeof Model)._models[this.id];

    return this;
  }

  static parseData(data: any, cacheModels: boolean = true): any {
    if (Array.isArray(data)) {
      const results = {};
      data.forEach((item) => {
        const result = this._parseData(item, cacheModels);
        for (const key in result) {
          if (results[key]) {
            results[key].push(result[key]);
          } else {
            results[key] = [result[key]];
          }
        }
      });

      return results;
    }

    return this._parseData(data, cacheModels);
  }

  static _parseData(data: any, cacheModels: boolean = true): any {
    const parsingData = {};
    const results = {};

    for (const key in data) {
      const index = key.indexOf('_');
      const modelName = index === -1 ? null : key.substring(0, index);
      if (modelName && this._registeredModels[modelName]) {
        if (parsingData[modelName]) {
          parsingData[modelName][key.substring(index + 1)] = data[key];
        } else {
          parsingData[modelName] = { [key.substring(index + 1)]: data[key] };
        }
      }
    }

    for (const modelName in parsingData) {
      if (cacheModels) {
        results[modelName] = this._registeredModels[modelName].findOrCreate(
          parsingData[modelName],
        );
      } else {
        results[modelName] = this._registeredModels[modelName].createUncached(
          parsingData[modelName],
        );
      }
    }

    return results;
  }

  static async fetchAll(): Promise<Record<string, Model>> {
    if (!this._fetchAllPromise) {
      this._fetchAllPromise = this._performFetchAll();
    }

    return this._fetchAllPromise;
  }

  static async fetch(id: string, { force = false } = {}): Promise<Model> {
    if (!id) {
      throw new Error('missing id');
    }

    const modelData = await Request(`${this.apiPath}/${id}`);

    const model = this._parseFetchData(modelData);

    return model;
  }

  static async _performFetchAll(): Promise<Record<string, Model>> {
    const result = await Request(this.apiPath);
    const results = this.parseFetchData(result);
    return results;
  }

  static parseSortedFetchData(data: any): any {
    if (Array.isArray(data)) {
      return data.map((item) => this._parseFetchData(item));
    }

    return this._parseFetchData(data);
  }

  static parseFetchData(data: any): any {
    if (Array.isArray(data)) {
      const results = {};
      data.forEach((item) => {
        const result = this._parseFetchData(item);
        if (result) {
          results[result.id] = result;
        }
      });

      return results;
    }

    return this._parseFetchData(data);
  }

  static _parseFetchData(data: any): any {
    const objectType = data.object_type;
    const ModelKlass = this._registeredModels[objectType];

    if (!ModelKlass) {
      return null;
    }

    return new ModelKlass(data);
  }

  static registerModel(key: string, klass: any): void {
    this._registeredModels[key.toLowerCase()] = klass;
    klass._hasReadAll = false;
    klass._models = {};
    klass._readAllPromise = null;
  }
}

export default Model;
